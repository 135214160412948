/* Paths */

    @themepath : '/build/html/wp-content/themes/advantageservices/';

/* Paths */

/* IMPORTS */

    @import "@{themepath}/assets/fonts/upgrade.css";

/* IMPORTS */

/*****************************************
        Fonts Familys 
 ***************************************/
@font-family-heading: "upgrade",sans-serif;
@font-family-text: "upgrade-lights",sans-serif;
    
/*****************************************
        Colors
***************************************/

/* Company Palette */

@atlantic-yach-n-ship-red : #FF3300;
@atlantic-yach-n-ship-white : #ffffff;
@atlantic-yach-n-ship-black : #000000;
@atlantic-yach-n-ship-light-gray : #ABABAB;
@atlantic-yach-n-ship-gray : #343434;
@atlantic-yach-n-ship-blue : #00214D;
    
/* Importants */
@color-primary: @atlantic-yach-n-ship-blue;
@color-accent: @atlantic-yach-n-ship-red;
@color-accent-2: @atlantic-yach-n-ship-light-gray;
@color-heading: #00214D;
@color-texto: #333333;
@color-texto-lighter: #F2E7CB;
@color-hover-link: #2663b9;;

/* Others Colors */
@global-color-white : #ffffff;
@global-color-black : #000000;

@color-dark-grey: #707070;

@main-white: #ffffff;
@main-gold: #FFB82A;
@main-gold-dark: #B19E64;
@main-blue: #001D46;
@main-dark-blue: #011529;
@main-light-blue: #7287A5;
@main-lighter-blue: #92BFFF;
@main-blue-green: #051C2C;
@main-light-grey: #EBEEF2;
@main-dark-grey: #9d9d9d;
@main-accent-grey: #F1F1F1;
    
/*****************************************
        Screens Break
***************************************/
@screem-phone: 500px;
@screem-tablet: 768px;
@screem-laptop: 1024px;
@screem-desktop: 1400px;


/* Fonts */

    /* Global */

        @global-font-size: 16px;
        @global-font-size-responsive: 16px;
        @global-letter-spacing: 0.06em; // 60 Characters Spacing in XD => (XD Characters Spacing) / 1000 = EM's In CSS
        @global-main-title-size: 2.181818rem;
        @global-gray-title-size: 1.09rem;
        @nav-font-size : 1.181818rem;
        @nav-active-font-size : 1.363636rem;
        @global-font-weight: 500;
        @active-font-weight: 500;
        @super-bold-font-weight: 700;
        @global-line-height: 1.636363rem;
        @global-li-line-height: 29px;
        @text-shadow: 1px 1px 2px fadeout(@global-color-black,35%);

    /* Global */

    /* Upgrade */

        @global-fonts-upgrade : "upgrade",sans-serif;

    /* Upgrade */

    /* Upgrade Light */

        @global-fonts-upgrade-light : "upgrade-lights",sans-serif;

    /* Upgrade Light */



/* Mixins */

.absoluteCentered(){
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.flexBoxCenteredContainer(){
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content:center;
}

.wp-bg(@y : center ,@x : center ,@fixedOrInit : initial){
    background-size:cover;
    background-repeat:no-repeat;
    background-position: @y @x;
    background-attachment:@fixedOrInit;
}


/* Mixins */