@import (reference) "variables.less";

@media screen and (max-width: 1260px){

    body, html{

        font-size:@global-font-size-responsive;

    }

    .color-n-color-title(){
    
        span{
            margin-inline-start: 5%;
        }
    
    }

}

@media screen and (max-width: 1150px){
    .not-mobile{
        display:none !important;
    }
}

@media screen and (max-width: 425px){
    p{
        text-align:justify;
    }
    

    .specs-list{
        margin: 1.909rem .5rem!important;   
    }

}