@import "variables.less";

/* Setting Browser Defaults */

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: @global-fonts-upgrade-light;
    font-size: @global-font-size;
    letter-spacing: @global-letter-spacing;
    font-weight: @global-font-weight;
    line-height: @global-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: @global-font-weight;
    line-height: normal;
}

h1 {
    font-size: @global-main-title-size;
}

li,
dt,
dd {
    line-height: @global-li-line-height;
}

img {
    width: 100%;
    max-width: 100vw;
}

.logo img {
    object-fit: contain;
}

.wrapper {
    display: flex;
    flex-flow: row wrap;
}

.container {
    max-width: 80%;
    margin: auto;
}

.generate-columns(@n, @i: 1) when (@i =< @n) {
    .col-@{i} {
        width: (@i * 100% / @n);

        @media screen and (max-width:850px) {
            width: 100%;
        }
    }

    .generate-columns(@n, (@i + 1));
}

.generate-columns(100);

.col-33 {
    width: 33.33%;

    @media screen and (max-width:850px) {
        width: 100%;
    }
}

.col-25 {
    width: 25%;

    @media screen and (max-width:850px) {
        width: 100%;
    }
}

.modalUnderlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
    z-index: 10;
    transition: 1s;
    display: none;

    .modalWindow {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: linear-gradient(rgba(255, 255, 255, .7), white);
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: 3rem;
        border-radius: .5rem;
        border: 2px solid white;
        box-shadow: 1px 1px 1px #f36623;
        max-width: 95%;

        .modalContent {
            position: relative;
            text-align: center;
            max-width: 100%;

            .modalClose {
                position: absolute;
                top: 0.3%;
                right: 0.5%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 2rem;
            }

            .modalLogo {
                margin: 2rem;
            }
        }
    }
}

.global-bottom-frame {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: -1;
}

/* Setting Browser Defaults */


/* Setting Defaults */

.color-n-color-title() {
    font-size: @global-main-title-size;
    line-height: 57px;
    text-transform: uppercase;
    margin: 0;

    span {
        font-size: @global-gray-title-size;
        line-height: normal;
        display: block;
        margin-inline-start: 12% !important;

        @media screen and (max-width: 1260px) {
            margin-inline-start: 5% !important;
        }
    }

    em {
        color: @atlantic-yach-n-ship-red;
        font-style: normal;
    }
}

.gray-n-blue-title {
    .color-n-color-title();
    color: @atlantic-yach-n-ship-blue;

    span {
        color: @atlantic-yach-n-ship-light-gray;
    }
}

.white-n-white-title {
    .color-n-color-title();
    color: @atlantic-yach-n-ship-white;
    text-shadow: 1px 1px 2px @atlantic-yach-n-ship-black;
}

.blue {
    color: @atlantic-yach-n-ship-blue;
}

.full-screen-image {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;

    picture {
        flex-grow: 1;
        display: flex;

        img {
            max-width: 100%;
            width: 100%;
            margin-bottom: -10px;
            object-fit: cover;
            object-position: center;
        }
    }
}


.specs-list {
    display: grid;
    grid-template-columns: .5fr 2fr;
    margin: 1.909rem 3.5rem !important;

    color: @atlantic-yach-n-ship-blue;
    font-size: 1.090909rem;

    dt,
    dd {
        line-height: 36px;
    }

    dt {
        text-transform: uppercase;
    }

    dd {
        color: @atlantic-yach-n-ship-gray;
    }
}

#sidebar {


    h2.gray-n-blue-title {
        text-align: left !important;
        line-height: normal !important;

        span {
            text-align: left !important;
            line-height: normal !important;
            margin-inline-start: 10% !important;
        }
    }
}

form {
       /*Start New Style V2*/

       .cog-heading {
        font-size: 2.181818rem;
        line-height: 57px;
        text-transform: uppercase;
        margin: 0;
        color: #00214d!important;
    }

    /*End New Style V2*/
}

.cognito {
    margin-top: 0 !important;

    /*Start New Style V2*/

    .cog-heading {
        font-size: 2.181818rem;
        line-height: 57px;
        text-transform: uppercase;
        margin: 0;
        color: #00214d!important;
    }

    /*End New Style V2*/
    .gray-n-blue-title {
        text-align: left;

        span {
            margin-inline-start: 0;
        }
    }

    label {
        padding-bottom: 0.9rem;
    }

    div {
        visibility: hidden;
    }

    div.c-forms-form {
        visibility: visible;
        background-color: transparent;

        * {
            visibility: visible;
        }
    }

    .gray-n-blue-title {

        span {

            em {
                font-style: normal;
                color: @atlantic-yach-n-ship-red;
            }

        }
    }

    form {
        width: 17.875rem;
        max-width: 100%;
        margin: auto !important;

        * {
            font-family: @global-fonts-upgrade-light !important;
            font-weight: normal !important;
            font-size: 1rem !important;
            color: @atlantic-yach-n-ship-blue !important;
            letter-spacing: 0.068em !important;
            line-height: 26px !important;
            background-color: transparent;
        }


        .c-button-section {
            text-align: right;
            padding-bottom: 0 !important;
        }

        .c-action {
            float: left !important;
        }

        .c-field {
            margin-bottom: 20px !important;
        }

        .c-button {
            background-color: white !important;
            border: 2px solid @atlantic-yach-n-ship-blue !important;
            font-weight: 400 !important;
            text-transform: uppercase !important;
            padding: 10px 20px !important;
            color: @atlantic-yach-n-ship-blue !important;
            border-radius: 30px !important;
            font-size: 1.636363rem !important;
            font-weight: 500 !important;
        }

        .c-forms-heading {
            display: none;
        }

        input {
            border: 0 !important;
            border-bottom: 1px solid @atlantic-yach-n-ship-blue !important;

            &::placeholder {
                color: @atlantic-yach-n-ship-blue !important;
                font-family: @global-fonts-upgrade-light !important;
                font-weight: normal !important;
                font-size: 1rem !important;
                letter-spacing: 0.068em !important;
                line-height: 26px !important;
            }
        }

        textarea {
            border-color: @atlantic-yach-n-ship-blue !important;
            height: 6.8181rem !important;
            background-color: white !important;
        }

    }

}

.broker {
    display: grid;
    grid-template-columns: 1fr 2.25fr;
    grid-template-rows: auto;
    grid-template-areas:
        "picture info";

    margin: 3rem 0;

    main {
        grid-area: info;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            "name"
            "title"
            "content";
        padding: 15px;
        padding-top: 0;

        h2.name {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            margin: auto 0;

            color: @atlantic-yach-n-ship-red;
            font-size: 1.181818rem;
            padding-top: 1rem;
        }

        h3 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;

            margin: auto 0;

            color: @atlantic-yach-n-ship-blue;
            font-size: 0.909090rem;
            text-transform: uppercase;


        }

        .contact {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;

            p {
                margin: auto;
                font-size: 0.818181rem;
                line-height: 28px;
            }

        }

    }

    aside {
        grid-area: picture;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        display: flex;
        flex-flow: column nowrap;

        img {
            max-width: 100%;
            flex-grow: 1;
            object-fit: cover;
            object-position: center;
        }
    }
}


.vessel-card {
    display: grid;
    grid-template-columns: .75fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "picture info";
    border: 2px solid @atlantic-yach-n-ship-blue;

    .image {
        grid-area: picture;
    }

    .content {
        grid-area: info;
    }

}

.three-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.four-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.vessel-card {
    margin: 15px 0;
    padding: 2px;
    background-color: white;
    position: relative;
    align-self: baseline;
    font-size: 1.2rem;

    .content {
        background-color: @global-color-white;
        display: grid;
        font-size: 1.1rem;
        line-height: 22px;

        header {
            background-color: @atlantic-yach-n-ship-blue;
            padding: 0px 10px;
            display: flex;
            align-items: center;

            h2 {
                margin: 0;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 800;
                color: @global-color-white;
            }

        }

        h3 {
            margin: auto;
            color: @atlantic-yach-n-ship-blue;
            padding: 5px 10px;
        }

        p {
            margin: auto 0;
            padding: 5px 10px;
        }

        abbr {
            border-bottom: 0;
            text-decoration: none;
            text-decoration-color: transparent;
        }

        address {
            font-size: 0.9rem;
            padding: 5px 10px;
        }

        footer {
            color: @atlantic-yach-n-ship-blue;
            background-color: #EAEAEA;
            font-weight: 800;
            padding: 0px 5px;
            display: flex;
            align-items: center;

            p {
                margin: 0;
                font-size: 1.4rem;
            }

        }

    }

    .image {
        display: grid;

        img {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 156px !important;
            max-height: 156px !important;
        }

    }

    .allBoxClick {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

/* Setting Defaults */

/* CTA's */

.cta() {
    display: block;
    font-size: 1.6363636rem;
    line-height: 36px;
    padding: 10px 20px;
    margin: 10px 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: fit-content;

    @media screen and (max-width:425px) {
        font-size: 1.2rem;
    }
}

.light-cta {
    @color-1 : @atlantic-yach-n-ship-white;
    @color-2 : @atlantic-yach-n-ship-blue;
    .cta();
    background-color: @color-1;
    border: 3px solid @color-2;
    border-radius: 30px;
    color: @color-2;
    transition: 0.6s;

    &:hover {
        background-color: @color-2;
        border-color: @color-1;
        color: @color-1;
    }
}

.dark-cta {
    @color-1 : @atlantic-yach-n-ship-blue;
    @color-2 : @atlantic-yach-n-ship-white;
    .cta();
    background-color: @color-1;
    border: 3px solid @color-2;
    border-radius: 30px;
    color: @color-2;
    transition: 0.6s;

    &:hover {
        background-color: @color-2;
        border-color: @color-1;
        color: @color-1;
    }
}


/* CTA's */
@keyframes focus-in-expand {
    0% {
        letter-spacing: -0.5em;
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

.focus-in-expand {
    animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* Responsive */

@import "standards-responsive.less";

/* Responsive */