/* Imports */

@import "../standards.less";

@import "../includes/header.less";

@import "../includes/footer.less";

/*  Grid Definitions  */

body{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
    "hero"
    "title"
    "contnt"
    "footer";
}

#mastHeader{
    grid-area:hero;
    align-self: baseline;
}

#hero{
    grid-area:hero;
}

body>h1{
    grid-area: title;
}

#content{
    grid-area: contnt;
}

#mastFooter{
    grid-area: footer;
}

/*  Grid Definitions  */

/* Styling */

body{
    background-size: 135% auto;
    background-repeat: no-repeat;
    background-position: center 60vh;
    background-image: url(/wp-content/uploads/2020/03/logo-watermark.png);
    background-attachment:initial;
}

#hero{
    position:relative;
    flex-flow:row wrap;
    display:grid;
        picture{
            display:block;
            img{
                max-height:60vh;
                @media screen and (min-width:1420px){
                    height:60vh;
                }
            }
        }

    figcaption{
        font-size: 3.789473684210526vw;
        line-height: normal;
        text-transform: uppercase;
        padding: 0 20px;
        color: @atlantic-yach-n-ship-blue;
        text-align:right;
        h1{
            span{
                display:inline;
            }
        }
    }

}

body>h1{

    text-align:right;
    padding:20px 40px;
    padding-top:1.5rem;
    margin-bottom:0;
    padding-bottom:0;
    font-size:@global-main-title-size;
    color:@atlantic-yach-n-ship-blue;
    margin-top:0;
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-content: flex-end;
    text-transform:uppercase;
    letter-spacing:0.05em;
    line-height:2.181818rem;


    span{
        font-size:@global-gray-title-size;
        color:@atlantic-yach-n-ship-light-gray;
        letter-spacing:0.06em;
        line-height: 32px;
        padding-right:20px;
    }

}

#content{

    .perfectH2(){
        font-family:@global-fonts-upgrade;
        font-size:1.636363rem;
        color:@atlantic-yach-n-ship-blue;
        width:100%;
        border-bottom:2px solid @atlantic-yach-n-ship-blue;
        letter-spacing:0.06rem;
        text-transform:uppercase;
        margin-bottom:0;
        @media screen and (max-width: 700px){
            font-size:1.2rem;
        }
    }
        
    display:grid;
    grid-template-columns: 1.1fr 3fr;
    grid-template-rows: auto;
    grid-template-areas:
    "sidebar description";

    #sidebar{
        grid-area: sidebar;
        padding:0 20px;

        @media screen and (min-width:1450px){
            margin: -1.7rem auto;
        }
        .cognito{

            h2.gray-n-blue-title{
                width:fit-content;
                margin:auto;
                span{
                    text-align:center !important;
                    margin-inline-start: 0% !important;
                }
            }
        }
    }

    &>.content{
        grid-area:description;
        display:grid;
        grid-template-columns: 1fr 327px;
        grid-template-rows: auto auto auto auto;
        grid-template-areas: "info info" "searchtitle searchtitle" "search search" "content content" "tble tble";
        padding:0 40px;
        align-self: baseline;

        .info{
            grid-area: info;
            font-size:1rem;
            color:@atlantic-yach-n-ship-blue;
            letter-spacing: 0.06em;
            line-height:28px;
            width:100%;
            padding:30px 0;
            
            &>h2{
                .perfectH2();
            }

            #featured-results{
                display: grid;
                grid-template-areas:
                "first first first second"
                "third four four four"
                "third five five five";
                grid-column-gap: 3.3rem;
                grid-row-gap:3.3rem;
                margin-bottom:10px;

                .service-box{
                    display:grid;
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                    position:relative;                    
                    a{
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                    &:nth-child(1){
                        grid-area:first;
                        max-width:100%;
                    }
                    &:nth-child(2){
                        grid-area:second;
                        max-width:100%;
                        picture{
                            img{
                                min-height:100%;
                            }
                        }
                    }
                    &:nth-child(3){
                        grid-area:third;
                        max-width:100%;
                        max-height: 732px;
                    }
                    &:nth-child(4){
                        grid-area:four;
                        max-width:100%;
                    }
                    &:nth-child(5){
                        grid-area:five;
                        max-width:100%;
                    }

                    h2{
                        color: @atlantic-yach-n-ship-blue;
                        writing-mode: vertical-lr;
                        transform: scale(-1);
                        position: absolute;
                        left: 0;
                        margin: 0;
                        line-height: 2rem;
                        left: -2rem;
                        text-transform: uppercase;
                        font-size: 1rem;
                        font-weight: 600;
                        z-index:4;
                        background-color:@global-color-white;
                        padding-top: 10px;
                        transition:.6s;
                    }
                    p{
                        grid-column-start:1;
                        grid-column-end:2;
                        grid-row-start:1;
                        grid-row-end:2;
                        text-align:right;
                        align-self:end;
                        font-size:2.5vw;
                        color:white;
                        text-transform:uppercase;
                        line-height: 2.7vw;
                        margin: 0;
                        padding: 10px;
                        opacity:0;
                        transition:.6s;
                        span{
                            position:relative;
                        }
                        &:before{
                            content:"";
                            display:block;
                            position:absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height:100%;
                            background-color:fadeout(@atlantic-yach-n-ship-blue,40%);
                        }
                    }

                    picture{
                        height: 100%;
                        grid-column-start: 1;
                        grid-column-end: 2;
                        grid-row-start: 1;
                        grid-row-end: 2;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr;
                        img{
                            width:100%;
                            max-width:100%;
                            object-fit:cover;
                            object-position:center;
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;
                            min-height:100%;
                        }
                    }
                    &:before{
                        position:absolute;
                        z-index:3;
                        left:-1rem;
                        bottom:-1rem;
                        width:1px;
                        //height:calc(100% + 1rem);
                        height:0%;
                        content:"";
                        display:block;
                        background-color:@atlantic-yach-n-ship-blue;
                        transition:1s;
                    }
                    
                    &:after{
                        position:absolute;
                        z-index:3;
                        left:-1rem;
                        bottom:-1rem;
                        //width:calc(100% + 1rem);
                        width:0%;
                        height:1px;
                        content:"";
                        display:block;
                        background-color:@atlantic-yach-n-ship-blue;
                        transition:1s;
                    }

                    &:hover{
                        &:before{
                            height:calc(100% + 1rem);
                        }
                        &:after{
                            width:calc(100% + 1rem);
                        }
                        h2{
                            opacity:0;
                        }
                        p{
                            opacity:1;
                        }
                    }

                }

            }
        }

        .image{
            grid-area:picture;
            align-self: flex-end;
        }

        .search-title{
            grid-area: searchtitle;
            align-self: flex-end;
            max-width: 100%;

            h2{
                .perfectH2();
            }
            p{
                font-size:1rem;
                letter-spacing:0.06rem;
                line-height:28px;
                color:@atlantic-yach-n-ship-blue;
            }

        }

        .search{    
            grid-area: search;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                "results results results results"
                "cta . . .";

                .light-cta{
                    grid-area:cta;
                }

        }

        #results{
            grid-area: results;
    
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 50px;

        }

        &>.content{
            grid-area:content;
            font-size:1rem;
            color:@atlantic-yach-n-ship-blue;
            line-height:28px;
            margin-bottom:50px;

            h2{
                .perfectH2();
            }
        }

    }

}

#yachts-by-country{
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom:20px;
    list-style:none;
    margin:0;
    li{
        color:@atlantic-yach-n-ship-blue;
        a{
            color: inherit;
            text-decoration: none;
        }
    }
}

.vessel-card{
    padding:0;
}




.cognito {
    position: sticky;
    top: 4rem;
}

.location-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: .5rem;
}

.location-line {
    height: 1px;
    width: 100%;
    background-color: #00214d;
    margin-bottom: 2rem;
}

.location-box {
    max-height: 21.5vh;
    width: 100%;
    border: 1px solid #00214d;
    display: flex;
    flex-flow: row nowrap;
}

.location-box-col-1 {
    width: 25%;
    background-color: #f5f5f5;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.location-box-col-2 {
    width: 75%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .map-logo-position {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            position: relative;
            max-width: 12vw;
            top: -25px;
        }
    }
}

.location-contact-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: .5rem;
    .location-contact-box-col-1 {
        font-size: 1.1rem;
        .mobile-br {
            display: none;
        }
    }
    .location-contact-box-col-2 {
        text-align: right;
        font-size: 1.1rem;
        .location-contact-info {
            a {
                text-decoration: none;
                color: initial;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .location-phones {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;
                img {
                    width: 20px;
                    margin-right: .5rem;
                }
            }
            .location-fax {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .location-email {
                border: 2px solid #00214d;
                border-radius: 20px;
                width: auto;
                width: fit-content;
                margin: .5rem 0 0 auto;
                padding: 0 .5rem;
            }
            .location-margin {
                margin: 0 .5rem;
            }
        }
    }
}


/* Styling */


@import "./contact-us-responsive.less";