@media screen and (max-width: 1890px){

    #mastFooter{

        .footer-nav{

            ul{
                padding-right:5px;
            }

        } 

        .footer-offices{

            ul{
                padding-right:5px;

                li{
                    padding: 0 5px;
                }

            }

        }

    }

}

@media screen and (max-width: 1725px){

    #mastFooter{
        grid-template-areas:
        "vet number footernav"
        "vet number footernav"
        "asnet asnet asnet";

        .footer-nav{
            align-self: center;
            justify-self: end;

            ul{
                display:grid;
                grid-template-columns: repeat(6,auto);
                grid-template-rows: 1fr 1fr;
                grid-auto-flow: dense;
                li{
                    align-self:center;
                    justify-self: end;
                    &.social{
                        grid-column-start: 1;
                        grid-column-end: 7;
                        grid-row-start: 2;
                        grid-row-end: 3;
                    }
                    &:nth-child(7) {
                        padding-right: 12rem;
                    }
                    &:nth-child(8) {
                        padding-right: 9rem;
                    }
                    &:nth-child(9) {
                        padding-right: 6rem;
                    }
                    &:nth-child(10) {
                        padding-right: 3rem;
                    }
                    &:nth-child(11) {
                        padding-right: 10px;
                    }

                }

            }

        }

        .footer-offices{
            display:none;
        }

        .asnet{
            padding:15px;
        }
    
    }

}

@media screen and (max-width: 930px){
    
    #mastFooter{

        .footer-nav{

            ul{

                li{
                    padding: 0 5px;
                }

            }

        }

    }


}

@media screen and (max-width: 860px){

    #mastFooter{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        "vet number"
        "footernav footernav"
        ". asnet";
        padding:0 15px;
        .number{
            align-items: center;
            display: grid;
        }
        .footer-nav{
            align-self: initial;
            justify-self: initial;
            padding-top:10px;
            justify-content: center;
            ul{
                display:flex;
                flex-flow:row wrap;
                justify-content: center;
                li{
                    width:50%;
                    text-align:center;
                    display:block;
                    padding:0 10px !important;

                    &.social{
                        padding-top:15px !important;
                        display: inline-block;
                        width: auto;
                    }

                }

            }

        }

        .number{
            text-align:center;
        }

        .asnet{
            text-align:center;
            padding-top:20px;
        }

        .vet{
            justify-self: center;
        }
        
        .footer-offices{
            display:none;

            ul{
                display: flex;
                flex-flow: row wrap;
                padding:10px;
                padding-top: 15px;

                li{
                    width:50%;
                    text-align:center;
                }

            }

        }

    }

}

@media screen and (max-width: 600px){

    #mastFooter{
        grid-template-columns: 75px auto;
        grid-template-rows: auto;
        grid-template-areas:
        "vet footernav"
        "vet number"
        "vet asnet";

        .footer-nav{
            align-self: initial;
            justify-self: initial;
            padding-top:10px;
            ul{
                display:flex;
                flex-flow:row wrap;

                li{
                    width:50%;
                    text-align:center;
                    display:none;
                    padding:0 10px !important;

                    &.social{
                        padding-top:15px !important;
                        display: inline-block;
                        width: auto;
                        margin: initial;
                    }

                }

            }

        }

        .number{
            text-align:right;
        }

        .asnet{
            text-align:right;
        }
        
        .footer-offices{
            display:none;
        }

    }

}