@import (reference) "../standards.less";

@media screen and (max-width: 1300px){

    body>h1{
        flex-flow:column nowrap;
    }

}


@media screen and (max-width: 1300px){
    #content{
        #sidebar{
            padding:0 10px;
            .cognito{
                padding:0;
            }
        }
        &>.content{
            .search{
                grid-template-areas:
                "results results results results"
                "cta cta . .";
            }
        }
    }
}

@media screen and (max-width: 1000px){
    #content{
        grid-template-areas:
        "description description"
        "sidebar sidebar";
        &> .content{
            padding:20px;
            grid-template-columns:1fr;
            .info{
                #featured-results{
                    padding:20px;
                }
            }
        }
    }
    #content>.content .info #featured-results article figure picture img{
        height:auto;
    }

    body>h1{
        flex-flow:column nowrap;
        padding:10px;
        text-align: center;
    }

    h1 span {
        padding: 0 !important;
        margin-bottom: .5rem !important;
    }

    .content .info {
        padding: 0 !important;
    }

    .location-title {
        font-size: 1.2rem !important;
    }

    .location-line {
        margin-bottom: 1rem !important;
    }

    .location-contact-row {
        flex-flow: column !important;
        margin-bottom: 3rem !important;
    }

    .location-phones {
        flex-flow: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .location-contact-box-col-1 {
        font-size: .95rem !important;
    }

    .location-box-col-1 {
        width: 35% !important;
    }

    .location-box-col-2 {
        width: 65% !important;
    }

    .location-margin {
        margin: 0 14px !important;
    }

    .mobile-br {
        display: initial !important;
    }

    .location-box-col-2 .map-logo-position img {
        top: 0 !important;
        min-width: 125px !important;
        max-width: 33vw !important;
    }

}

@media screen and (max-width: 700px){

    #content>.content .info #featured-results {
        grid-template-columns: 1fr;
        grid-template-areas:
            "first"
            "second"
            "third"
            "four"
            "five";
        grid-row-gap:1rem;
        grid-column-gap:1rem;
    }
    #content>.content .info #featured-results .service-box picture img{
        height:auto !important;
        min-height:auto !important;
    }

    #content>.content .info #featured-results .service-box h2 {
        writing-mode: initial;
        transform: initial;
        position: initial;
    }

    .vessel-card{
        grid-template-areas:
        "picture picture"
        "info info";
    }

    #content>.content{
        padding: 5px 10px;
    }

    #content>.content #results, #content>.content .info #featured-results{
        padding:5px;
    }
}

@media screen and (max-width: 445px){
    figcaption h1{
        font-size:1.7rem;
    }
    
    #content>.content .info #featured-results, #content>.content #results{
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 410px){
    #content>.content .search {
        grid-template-areas:
            "results results results results"
            "cta cta cta .";
    }
}