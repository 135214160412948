/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * upgrade:
 *   - http://typekit.com/eulas/00000000000000003b9b195f
 *   - http://typekit.com/eulas/00000000000000003b9b1961
 *   - http://typekit.com/eulas/00000000000000003b9b1899
 *   - http://typekit.com/eulas/00000000000000003b9b1965
 * upgrade-lights:
 *   - http://typekit.com/eulas/00000000000000003b9b189e
 *   - http://typekit.com/eulas/00000000000000003b9b1969
 *   - http://typekit.com/eulas/00000000000000003b9b196e
 *   - http://typekit.com/eulas/00000000000000003b9b196f
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-03-11 15:29:16 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=hex2gtg&ht=tk&f=38094.38096.38098.38100.38103.38104.38109.38110&a=21273103&app=typekit&e=css");

@font-face {
font-family:"upgrade";
src:url("https://use.typekit.net/af/f2973f/00000000000000003b9b195f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f2973f/00000000000000003b9b195f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f2973f/00000000000000003b9b195f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:400;
}

@font-face {
font-family:"upgrade";
src:url("https://use.typekit.net/af/98f2d1/00000000000000003b9b1961/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/98f2d1/00000000000000003b9b1961/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/98f2d1/00000000000000003b9b1961/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:700;
}

@font-face {
font-family:"upgrade";
src:url("https://use.typekit.net/af/996915/00000000000000003b9b1899/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/996915/00000000000000003b9b1899/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/996915/00000000000000003b9b1899/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:700;
}

@font-face {
font-family:"upgrade";
src:url("https://use.typekit.net/af/7bab4f/00000000000000003b9b1965/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/7bab4f/00000000000000003b9b1965/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/7bab4f/00000000000000003b9b1965/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:400;
}

@font-face {
font-family:"upgrade-lights";
src:url("https://use.typekit.net/af/7ffdff/00000000000000003b9b189e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/7ffdff/00000000000000003b9b189e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/7ffdff/00000000000000003b9b189e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:500;
}

@font-face {
font-family:"upgrade-lights";
src:url("https://use.typekit.net/af/f4d475/00000000000000003b9b1969/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/f4d475/00000000000000003b9b1969/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/f4d475/00000000000000003b9b1969/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:500;
}

@font-face {
font-family:"upgrade-lights";
src:url("https://use.typekit.net/af/e86726/00000000000000003b9b196e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/e86726/00000000000000003b9b196e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/e86726/00000000000000003b9b196e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:400;
}

@font-face {
font-family:"upgrade-lights";
src:url("https://use.typekit.net/af/3efa2e/00000000000000003b9b196f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/3efa2e/00000000000000003b9b196f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/3efa2e/00000000000000003b9b196f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:400;
}

.tk-upgrade { font-family: "upgrade",sans-serif; }
.tk-upgrade-lights { font-family: "upgrade-lights",sans-serif; }
