@import (reference) "../standards.less";
#mastFooter{
    display:grid;
    grid-template-columns: 75px auto auto;
    grid-template-rows: auto;
    background-color: @atlantic-yach-n-ship-blue;
    grid-template-areas:
    "vet number footernav"
    "vet asnet offices";
    color:white;
    font-size:@nav-font-size;
    letter-spacing: 0.068em;
    position:relative;

    .vet{
        max-width:100%;
        width:75px;
        grid-area:vet;
        align-self:center;
        padding:5px;
    }

    a{
        
        
        display:block;
        color:inherit;
        text-decoration:none;

    }

    .number{
        grid-area:number;
        padding-top:0.736363rem;
        padding-left: 1.363636rem;
    }

    .footer-nav{
        grid-area: footernav;

        ul{
            display:flex;
            list-style:none;
            margin:0;
            padding:0;
            justify-content: flex-end;
            padding-right:50px;

            li{
                display:flex;
                flex-flow:column nowrap;
                align-items: flex-end;
                justify-content: flex-end;
                padding: 0 12px;

                &.social{
                    padding-top:10px;
                    font-size:1.727272rem;
                }

            }

        }
        
    }

    .asnet{
        grid-area: asnet;
        opacity:.3;
        transition:.5s;
        padding-left: 1.363636rem;
        text-align:right;
        &:hover{
            opacity:1;
        }
        svg{
            g{
                transition:.5s;
            }
            &:hover{
                g{
                    opacity:1;
                }
            }
        }
    }

    .footer-offices{
        grid-area: offices;
        font-size:0.636363rem;
        ul{
            display:flex;
            padding-right:50px;
            list-style:none;
            margin:0;
            justify-content:flex-end;
            li{
                padding: 0 4px;
                position: relative;
                font-size: 0.6rem;

            }
        }
    }

}

@import "footer-responsive.less";