@import (reference) "../standards.less";

@media screen and (max-width:1600px){
    #mastHeader{

        &.active{

            #main-nav{

                ul{
    
                    li{
                        font-size:1.181818rem;
                    }
                    
                }
                
            }

        }

    }

}

@media screen and (max-width:1430px){

    #mastHeader{

        #main-nav{

            ul{

                li{
                    padding:1rem 0.5rem;
                }
                
            }
            
        }
        
    } 

}

@media screen and (max-width:1400px){
    
    #mastHeader{

        &.active{
/*
            .mega-menu{
                grid-template-columns: 1fr 4fr;
                grid-template-rows: initial;

                .sub-menu{
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                .our-listings{
                    grid-column-start:2;
                    grid-column-end: 3;
                    grid-row-start:1;
                    grid-row-end: 2;
                    .listings{
                        display:grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-column-gap: 10px;
                    }
                }

                .yellowfin{
                    grid-column-start: 2;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 4;
                }

            }
            */
        }

    }
}

@media screen and (max-width:1270px){

    #mastHeader{

        #main-nav{

            ul{

                li{
                    font-size:1rem;
                }
                
            }
            
        }
        
    } 

}

@media screen and (max-width:1200px){

    #mastHeader{
        position:sticky;
        top:0;
        z-index: 9999;

        #header-logo-container{
            display:none;
        }

        #main-nav{
            display:none;
        }

        .mega-menu{
            display:none;
        }

        #mobile-header{

            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start:1;
            grid-row-end:2;
            
            display:grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "bar bar";


            #mobile-top-bar{
                // background-color:  @atlantic-yach-n-ship-blue;
                background-color: transparent;

                display:flex;
                justify-content:space-around;
                align-items:center;

                grid-area: bar;

                z-index:9999;

                
                #mobile-header-logo-container{
                    grid-area: logo;
                    
                    display: inline;


                    padding: 10px;
                    padding-bottom:0;

                    #mobile-logo-black{
                        display:none;
                    }

                    .logo{

                        img{
                            max-width:100%;
                            max-height: 40px;
                            object-position:center;
                        }
                        
                    }

                }

                #mobile-hamburger{
                    grid-area: hamburger;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding:10px;

                    button{
                        background-color:transparent;
                        border:0;
                        font-size:1rem;
                        color:@global-color-white;
                        cursor:pointer;

                        &:focus{
                            outline: 0;
                        }

                    }

                }

            }

            #mobile-mega-menu{
                display:grid;
                grid-template-columns: 1fr;
                grid-template-rows: 4fr 1fr;
                overflow-y: scroll;
                overflow-x: hidden;

                position:fixed;
                top:0%;
                left:0;

                height:100%;
                width:100%;

                background-color:   @global-color-white;

                z-index:9998;

                padding-top:100px;

                display:none;

                &.active{
                    top:0;
                }

                &:before{

                    content: "";

                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    
                    background-color:   @global-color-white;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(/wp-content/uploads/2020/03/logo-watermark-darker.png);

                    transition: .6s;
                    z-index: -1;
                    transform: rotate(90deg) scale(4);
                }

                #mobile-nav{

                    &>ul{
                        list-style:none;
                        &>li{
                            font-size:1.625rem;
                            letter-spacing: 0.068rem;
                            line-height:52px;
                            text-transform:uppercase;
                            color: @atlantic-yach-n-ship-blue;

                            position:relative;


                            ul{
                                list-style:none;
                                padding-left:20px;

                                li{
                                    font-size:1.125rem;
                                    line-height:normal;
                                    padding:5px 0;

                                    a{
                                        padding-left: 25px;

                                        &:before{

                                            width:15px;
                                            height:15px;

                                        }
                                    }

                                }

                            }

                            a{
                                color:inherit;
                                text-decoration:inherit;
                                display:block;
                                padding-left:15px;
                                position:relative;

                                &:before{
                                    content: "";
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    height: 26px;
                                    width: 5px;
                                    background-color:  @atlantic-yach-n-ship-blue;
                                    transform: translateY(-50%);                         
                                }

                            }

                        }

                    }

                }

                #social-nav{
                    width:80%;
                    margin:0 auto;

                    ul{
                        list-style:none;
                        padding: 0;
                        display: flex;
                        
                        li{
                            display:inline-block;
                            font-size:1.875rem;
                            color:@atlantic-yach-n-ship-blue;
                            padding:0 5px;
                            margin:auto;

                            a{
                                color:inherit;
                                text-decoration: none;
                            }

                            svg{

                                path{
                                    fill: @atlantic-yach-n-ship-blue;
                                }

                            }

                        }

                    }

                    .number{
                        display:block;
                        width:100%;
                        text-align:right;
                        text-decoration:none;
                        color: @atlantic-yach-n-ship-blue;

                        font-size:1.625rem;
                        letter-spacing: 0.068rem;
                        line-height: 26px;

                        margin-top:20px;
                    }

                }

            }

            &.active{

                #mobile-mega-menu{
                    &:before{
                        background-image: url(/wp-content/uploads/2020/03/logo-watermark-darker.png);
                    }
                }
                
                #mobile-top-bar{
                    background-color:@atlantic-yach-n-ship-blue;
                    
                    #mobile-header-logo-container{
                        
                        #mobile-logo-black{
                            display:block;
                        }
        
                        #mobile-logo-original{
                            display:none;
                        }

                    }

                }

            }

        }

    }

}

@media screen and (max-width:425px){
    #mastHeader #mobile-header #mobile-mega-menu:before{
        transform: rotate(90deg) scale(2.6);
    }
}