@import (reference) "../standards.less";
#mastHeader{
    display:grid;
    grid-template-columns: 1.9434375fr 8.0565625fr;
    grid-template-rows: auto;

    width:100%;

    position:relative;
    z-index:4;

    &:before{
        content:"";
        display:block;

        position:absolute;
        bottom:0;
        left:0;

        width:100%;
        height:0%;

        background-color:white;
        background-size: 155% auto;
        background-repeat: no-repeat;
        background-position: center 0.4rem;

        z-index:-1;
    }

    &:after{
        content:"";
        display:block;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:65px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 10%, transparent);
        z-index:-1;
    }

    #header-logo-container{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    
        display:grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        z-index:2;

        .logo{
            grid-column-start:1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;

            // position:fixed;
            // width:19.434375%;
            height:auto;
            top:0;
            left:0;

            z-index:2;


            img{
                max-width:100%;
                padding:25px;
                filter: drop-shadow(2px 2px 2px @atlantic-yach-n-ship-black);
            }

            &#logo-black{
                display:none;
                z-index:-1;
                img{
                    filter:none;
                }
            }

        }

    }

    #main-nav{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        align-self: baseline;
        justify-self:end;

        ul{
            list-style:none;
            margin:0;
            padding:0;
            display:flex;
            li{
                color:white;
                font-size:@nav-font-size;
                text-shadow:  @text-shadow;
                text-transform:uppercase;
                padding:1rem;
                letter-spacing: 0.068em;
                a{
                    color:inherit;
                    text-decoration: none;
                    display:block;
                }
            }
        }
    }

    .mega-menu{
        display:none;
    }

    #mobile-header{
        display:none;
    }

    &.active{
        grid-template-rows: 1.648936fr 8.351064fr;

        position:fixed;
        top:0;
        left:0;

        width:100%;
        height:100%;

        z-index:99;

        &:before{
            height:100%;
            background-image:url('/wp-content/uploads/2020/03/logo-watermark-darker.png');
        }
        
        &:after{
            background: transparent !important;
        }

        .mega-menu{     
                
            .sub-menu{
                grid-area: submenu;
                font-size:1.090909rem;
                color: @atlantic-yach-n-ship-blue;
                padding-top: 70px;
                ul{
                    padding:0;
                    margin:0;
                    list-style:none;
                    padding-left:40px;
                    li{
                        line-height:40px;
                        position:relative;
                        padding-left:15px;
                        margin:10px 0;
                        cursor:pointer;
                        a{
                            text-decoration: none;
                            color:inherit;
                            text-transform:uppercase;
                        }
                        &:before{
                            content:"";
                            display:block;
                            position:absolute;
                            top:0;
                            left:0;
                            height:100%;
                            width:5px;
                            background-color: @atlantic-yach-n-ship-blue;
                        }
                    }
                }

            }
        }

        &[data-menu="buy"]{
            .mega-menu-buy{
                grid-column-start: 1;
                grid-column-end:3;
                grid-row-start:2;
                grid-row-end:3;

                
                display:grid;
                grid-template-columns: 1.9fr 1.3fr 2fr 6fr;
                grid-template-rows: auto;
                grid-template-areas:
                    "submenu ourlistings atlantic pearlyachts"
                    "submenu ourlistings yellowfin yellowfin"
                    "submenu ourlistings socials socials"
                    "submenu ourlistings socials socials";
                
                text-transform:uppercase;

                

                #buy-footer{
                    ul{
                        display:flex;
                        list-style:none;
                        margin:0;
                        padding:0;
                        justify-content: flex-end;
                        padding-right:50px;
            
                        li{
                            display:flex;
                            flex-flow:column nowrap;
                            align-items: flex-end;
                            justify-content: flex-end;
                            padding: 0 12px;
                            color:@atlantic-yach-n-ship-blue;
            
                            &.social{
                                padding-top:10px;
                                font-size:1.727272rem;
                            }

                            a{
                                color:inherit;
                                text-decoration: none;
                            }
                            
                            i{
                                color:inherit;
                            }

                            svg,path{
                                fill: @atlantic-yach-n-ship-blue !important;
                            }
            
                        }
            
                    }
                    
                    .footer-offices{
                        grid-area: offices;
                        ul{
                            display:flex;
                            padding-right:50px;
                            list-style:none;
                            margin:0;
                            justify-content:flex-end;
                            li{
                                padding: 0 4px;
                                position: relative;
                                font-size: 0.8rem;

                            }
                        }
                    }
                }

                .socials{
                    grid-area:socials;
                }

                .homepage-card{
                    position:relative;
                    margin-bottom:20px;
                    figure{
                        margin:0;
                        picture{
                            display:grid;
                        }
                        img{
                            width:100%;
                            height: 100px;
                            object-fit: cover;
                            object-position: center;
                        }
                        figcaption{
                            font-size:0.727272rem;
                            padding-top:0;
                            white-space: nowrap;
                        }
                    }
                    &>a{
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
                
                .sub-menu{
                    grid-area: submenu;
                    font-size:1.090909rem;
                    color: @atlantic-yach-n-ship-blue;
                    padding-top: 70px;
                    ul{
                        padding:0;
                        margin:0;
                        list-style:none;
                        padding-left:40px;
                        li{
                            line-height:40px;
                            position:relative;
                            padding-left:15px;
                            margin:10px 0;
                            cursor:pointer;
                            a{
                                text-decoration: none;
                                color:inherit;
                                text-transform:uppercase;
                            }
                            &:before{
                                content:"";
                                display:block;
                                position:absolute;
                                top:0;
                                left:0;
                                height:100%;
                                width:5px;
                                background-color: @atlantic-yach-n-ship-blue;
                            }
                        }
                    }

                }
                .title{
                    width:100%;
                    border-bottom: 1px solid @atlantic-yach-n-ship-blue;
                    margin: 10px 0;
                    font-size:1rem;
                }

                .our-listings{
                    grid-area: ourlistings;
                    padding: 0 5px;
                }

                .atlantic{
                    grid-area: atlantic;
                    padding: 0 5px;
                    padding-left:40px;
                    min-height:150px;
                }

                .pearl{
                    grid-area: pearlyachts;
                    padding: 0 5px;
                    padding-right:40px;
                    min-height:150px;

                    .listings{
                        display:grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-template-rows:auto;
                        grid-column-gap: 10px;
                    }
                }

                .yellowfin{
                    padding: 0 5px;
                    grid-area:yellowfin;
                    padding: 0 40px;
                    .listings{
                        display:grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: auto;
                        grid-column-gap: 10px;
                    }
                }
            }
        }

        &[data-menu="about"]{
            .mega-menu-about{
                grid-column-start: 1;
                grid-column-end:3;
                grid-row-start:2;
                grid-row-end:3;

                
                display:grid;

                grid-template-columns: 1.9434375fr 1.3020833fr 1.93fr 5.4523959fr ;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas: 
                "submenu . events events"
                "submenu . releases releases"
                "submenu . footer footer";

                
                .the-events{
                    display: grid;
                    grid-template-columns: repeat(5,1fr);
                    grid-column-gap: 10px;
                    .homepage-card{
                        position:relative;
                        margin-bottom:20px;
                        picture{
                            display:grid;
                        }
                        a{
                            position:absolute;
                            top:0;
                            left:0;
                            width:100%;
                            height:100%;
                        }
                    }
                    figure{
                        margin:0;
                    }
                    .homepage-card figure picture img {
                        width: 100%;
                        max-width: 100%;
                        height: 8.125rem;
                        -o-object-fit: cover;
                        object-fit: cover;
                        -o-object-position: center;
                        object-position: center;
                    }
                    .homepage-card figure figcaption {
                        line-height: normal;
                        text-align: left;
                        color: @atlantic-yach-n-ship-blue;
                        font-size: 14px;
                        font-weight: 900;
                        span{
                            font-weight:500;
                            display:block;
                        }
                    }
                }

                .events{
                    grid-area:events;
                    padding-right:40px;
                }

                .releases{
                    grid-area: releases;
                    padding-right:40px;
                }

                #about-footer{
                    grid-area:footer;
                    ul{
                        display:flex;
                        list-style:none;
                        margin:0;
                        padding:0;
                        justify-content: flex-end;
                        padding-right:50px;
            
                        li{
                            display:flex;
                            flex-flow:column nowrap;
                            align-items: flex-end;
                            justify-content: flex-end;
                            padding: 0 12px;
                            color:@atlantic-yach-n-ship-blue;
            
                            &.social{
                                padding-top:10px;
                                font-size:1.727272rem;
                            }

                            a{
                                color:inherit;
                                text-decoration: none;
                            }
                            
                            i{
                                color:inherit;
                            }

                            svg,path{
                                fill: @atlantic-yach-n-ship-blue !important;
                            }
            
                        }
            
                    }
                    
                    .footer-offices{
                        grid-area: offices;
                        ul{
                            display:flex;
                            padding-right:50px;
                            list-style:none;
                            margin:0;
                            justify-content:flex-end;
                            li{
                                padding: 0 4px;
                                position: relative;
                                font-size: 0.8rem;

                            }
                        }
                    }
                }
            }
            
            .title{
                width:100%;
                border-bottom: 1px solid @atlantic-yach-n-ship-blue;
                margin: 10px 0;
                font-size:1rem;
                text-transform:uppercase;
            }

        }
        
        #header-logo-container{
            align-self:baseline;
            .logo{
                position:relative;
                width:100%;
                align-self: end;
                justify-self: end;

                &#logo-original{
                    opacity:0;
                }
                &#logo-black{
                    display:block;
                    opacity:1;
                }

            }
        }

        #main-nav{
            align-self:initial;
            ul{

                li{
                    color:@atlantic-yach-n-ship-blue;
                    text-shadow:none;
                    &.active{
                        color:@atlantic-yach-n-ship-red;
                        font-size:@nav-active-font-size;
                    }
                }
            }
        }

        .close-header{

            display:none;
            position:absolute;
            font-family: @global-fonts-upgrade;
            top: 6%;
            right: 4%;
            background-color:transparent;
            color:@atlantic-yach-n-ship-red;
            font-size:2rem;
            border:0;
            cursor:pointer;

        }

    }

    .close-header{
        visibility: hidden; 
        display:none;
    }
   
}

@import "./header-responsive.less";


#mastHeader {
    transition: 200ms;
    height: 65px;
}

#mastHeader:hover {
    background-color: #00214dce;
    -webkit-box-shadow: 0px 17px 68px -26px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 17px 68px -26px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 17px 68px -26px rgba(0, 0, 0, 0.75);
    height: 55px;
}

#mastHeader #header-logo-container {
    height: inherit;
}

#mastHeader #header-logo-container:hover {
    height: 55px;
}

#mastHeader #header-logo-container .logo {
    max-width: 100%;
    transition: 200ms ease;
}


#mastHeader:hover #header-logo-container .logo {
    max-width: 60%;
    min-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#mastHeader #header-logo-container .logo img {
    padding: 25px;
    transition: 200ms ease;
    object-fit: contain;
}


#mastHeader:hover #header-logo-container .logo img {
    padding: 0px 5%;
    margin-top: 1%;
    transition: 200ms ease;
    align-self: center;
    transition: 200ms ease;
}

/** Dropdown Styles */

#mastHeader li.has-sub-menu {
    float: left;
    overflow: hidden;
}

li.has-sub-menu:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    margin-top: 10px;
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background-color: #00214dce !important;
    padding: 0px 4px 6px 6px;
    /** background: rgba(0, 0, 0, .4) !important; */
}

.dropdown-content,
.dropdown-content a:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dropdown-content a:first-child {
    padding-top: 6px;
}

.dropdown-content a {
    text-align: left;
    transition: 100ms ease-out;
}

.dropdown-content a span {
    transition: 100ms ease-out;
    width: fit-content;
    font-size: 1rem;
    float: none;
    text-decoration: none;
    display: block;
    padding: 1px 6px;
    border-bottom: solid 1px #00000000;
}

.dropdown-content a:hover span {
    letter-spacing: 3px;
    font-weight: 600;
    padding: 0px 6px;
    border-bottom: solid 3px #f30;
}

#mastHeader #header-logo-container .logo {
    pointer-events: none;
}